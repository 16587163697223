import * as React from "react"

export default function Page() {
    return (
        <div>
            <div className="container">
                <h1><span>Blog</span></h1>
            </div>
        </div>
    )
}
